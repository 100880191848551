<template>
      <div class="pa-2 calc-height">
        <v-tabs height="32" slider-size="2" color="#282828" class="text-capitalize" v-model="activeTab">
          <v-tab v-for="(tab, index) in getAdminWatchList" :key="index" :href="tab.link" class="fsize12 primaryColor text-capitalize">{{ tab.mwName }}</v-tab>
        </v-tabs>

        <v-tabs-items color="grey" class="primaryColor fsize14 " v-model="activeTab">
            <v-tab-item v-for="(mw, index) in getAdminWatchList" :key="index" :transition="false" >
                <div class="d-flex align-center justify-space-between px-5" v-if="mw.isEditable == 1">
                  <div class="d-flex align-center">
                      <v-switch :ripple="false" v-model="adminSwitch"  @click="onOrOffSmartWatch(mw)"></v-switch>
                      <div class="px-2 fsize14 min-w-100px">{{ adminSwitch ? 'Disable' : 'Enable' }}</div>
                      
                  </div>
                  <div class="d-flex align-center position-relative">
                      <v-text-field v-model="searchScrip" placeholder="Search to add scrips" outlined dense hide-details class="px-2 with-400"></v-text-field>
                        <v-sheet v-click-outside="callOutSide" :elevation="0" v-if="getSearchList.length > 0" transition="fade-transition" tile style="top: 60px;" class="searchContent with-400">
                          <ul class="pl-0 borderbox">
                            <li class="removeBorder searchlist px-2 py-3 body-2 border-bottom-light d-flex align-center pointer" v-for="(idx, index) in getSearchList" :key="index" >
                              <div>
                                {{ idx.formattedInsName }}
                              </div>
                              <v-spacer></v-spacer>
                              <label for="" class="fsize10 rounded min-width-40 pa-0 ml-3"><span :class="idx.exchange == 'NSE' ? 'searchbuyColor' 
                                : idx.exchange == 'BSE'  ? 'searchsellColor' : idx.exchange == 'NFO' ? 'cancelledColor' 
                                : 'cancelledColor' ">{{ idx.exchange }}</span>
                              </label>
                              <v-btn color="primary" @click="addToSmartMw(mw, idx)" class="mx-2" v-if="!idx.checked" depressed height="26" width="26">+</v-btn>
                              <v-btn color="success" class="mx-2" v-if="idx.checked" depressed height="26" width="26">&#10003;</v-btn>
                            </li>
                          </ul>
                        </v-sheet>
                    </div>
                  <div class="d-flex align-center"  >
                      <v-text-field v-if="false" :disabled="true" :maxlength="10" v-model="smartMwName" placeholder="Enter smartwatch name" outlined dense hide-details class="px-2"></v-text-field>
                      <v-btn v-if="false" :disabled="true" color="primary" depressed class="text-capitalize fsize12">Edit</v-btn>
                  </div>
                </div>
                <div class="overflow-y-auto min-height">
                  <v-simple-table class="ma-4">
                    <thead>
                      <tr class="border-bottom-dark" v-if="selectedCheckbox?.length">
                        <th colspan="7">
                          <v-btn @click="multiDelete()" :disabled="selectedCheckbox?.length == 0" depressed color="primary" class="fsize12 text-capitalize" height="26px" width="100px">Delete 
                            <span v-if="selectedCheckbox?.length">({{ selectedCheckbox?.length }})</span>
                          </v-btn>
                        </th>
                      </tr>
                      <tr>
                        <th class="text-center">
                          <v-checkbox v-model="selectAll"></v-checkbox>
                        </th>
                        <th class="text-left" >Symbol</th>
                        <th class="text-center">Exchange</th>
                        <th class="text-center">Exchange Segment</th>
                        <th class="text-right">Token</th>
                        <th class="text-right">PDC</th>
                        <th class="text-center" v-if="constsmartMwName == mw.mwName">Actions</th>
                      </tr>
                    </thead>
                      <!-- <draggable v-model="mw.scrips" @end="sortMwlist(mw, mw.scrips)" tag="tbody" v-if="mw.isEditable == 1">
                        <tr v-for="(i, idx) in mw.scrips" :key="idx">
                          <td class="text-center">
                            <v-checkbox :value="i" v-model="selectedCheckbox"></v-checkbox>
                          </td>
                          <td class="text-left">{{ i.formattedInsName }}</td>
                          <td class="text-center">{{ i.exchange }}</td>
                          <td class="text-center">{{ i.segment }}</td>
                          <td class="text-right">{{ i.token }}</td>
                          <td class="text-right">{{ i.pdc ? parseFloat(i.pdc).toFixed(2) : i.pdc }}</td>
                          <td class="text-center" v-if="constsmartMwName == mw.mwName">
                              <v-icon @click="callDeleteDialog(mw, i)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </draggable> -->
                      <!-- v-else -->
                      <tbody >
                        <tr v-for="(i, idx) in mw.scrips" :key="idx">
                          <td class="text-center">
                            <v-checkbox :value="i" v-model="selectedCheckbox"></v-checkbox>
                          </td>
                          <td class="text-left">{{ i.formattedInsName }}</td>
                          <td class="text-center">{{ i.exchange }}</td>
                          <td class="text-center">{{ i.segment }}</td>
                          <td class="text-right">{{ i.token }}</td>
                          <td class="text-right">{{ i.pdc ? parseFloat(i.pdc).toFixed(2) : i.pdc }}</td>
                          <td class="text-center" v-if="constsmartMwName == mw.mwName">
                              <v-icon @click="callDeleteDialog(mw, i)">mdi-delete</v-icon>
                          </td>
                        </tr>
                      </tbody>
                  </v-simple-table>
                </div>
            </v-tab-item>
        </v-tabs-items>

        <v-dialog v-model="confirmDialog" max-width="400">
          <v-card class="pa-8 cardBoxShadow rounded-lg">
            <div class="fsize14 line-height-2 primaryColor" >Are you sure want delete ?</div>
            <div class="d-flex justify-end pt-3">
                <v-btn height="32" width="86" @click="deleteScrip()" depressed outlined text color="light-blue" class="text-capitalize white--text fsize12 mr-2">
                  Confirm
                </v-btn>
                <v-btn height="32" width="86" @click="confirmDialog = false" depressed color="black" outlined text class="text-capitalize fsize12">
                  Cancel
                </v-btn>
            </div>
          </v-card>
        </v-dialog>
      </div>
</template>
<script>
import { mapGetters } from "vuex"
import draggable from 'vuedraggable'
export default {
    components: { draggable },
    data() {
        return {
            activeTab: 3,
            holdingSwitch: false,
            smartMwName: '',
            constsmartMwName : '',
            searchScrip: '',
            searchTimer: '',
            confirmDialog: false,
            selectedCheckbox: [],
            isMultiDelete: false
        }
    },
    computed: {
      ...mapGetters('adminMw', ['getAdminWatchList','getSearchList', 'getIsSmartWatch']),
      
      adminSwitch: {
        get() {
          return this.getIsSmartWatch
        },
        set(value) {
          this.$store.commit('adminMw/setIsSmartWatch', value)
        }
      },
      dragOptions() {
        return {
          animation: 300,
          group: "cards",
          disabled: false,
          draggable: ".taskCard",
          ghostClass: "ghost",
        };
      },
      selectAll: {
        get: function () {
          return this.getAdminWatchList[this.activeTab].scrips ? this.selectedCheckbox.length == this.getAdminWatchList[this.activeTab].scrips?.length : false;
        },
        set: function (value) {
          var selected = [];
          if (value) {
            this.getAdminWatchList[this.activeTab].scrips?.forEach(function (el) {
              selected.push(el);
            });
          }
          this.selectedCheckbox = selected;
        }
      }
    },
    async mounted() {
      await this.$store.dispatch('adminMw/getAdminWatchList')
      this.smartMwName = this.getAdminWatchList[3].mwName
      this.constsmartMwName = this.getAdminWatchList[3].mwName
    },
    watch: {
      searchScrip: function (searchVal) {
        if (searchVal && searchVal.length > 0) { 
          this.$store.commit('adminMw/setSearchList', [])
        }
        clearTimeout(this.searchTimer)
        if (searchVal && searchVal.length > 2) {
          this.searchTimer = setTimeout(() => {
            this.$store.dispatch('adminMw/searchScips', searchVal)
          }, 250)
        }
      },
    },
    methods: {
      addToSmartMw(mw, data) {
        let json = {
          "mwId": mw?.mwId,
          "mwName": mw?.mwName,
          "scrips": [
            {
              "token": data.token,
              "exchange": data.exchange
            },
          ]
        }
       this.$store.dispatch('adminMw/addmwScips', json)
       this.searchScrip = ''
       data.checked = true
       this.resetCheckbox()
    },
      callOutSide() {
        this.$store.commit('adminMw/setSearchList', [])
      },
      callDeleteDialog(mw, data) {
        this.isMultiDelete = false
        this.confirmDialog = true
        this.currentMw = mw
        this.currentScrip = data
      },
      deleteScrip() {
        let scripsIds = []
        if(this.isMultiDelete) {
          this.selectedCheckbox.forEach(el=> {
            scripsIds.push(el.id)
          })
        } else {
          scripsIds = [this.currentScrip.id]
        }
        let json = {
          "mwId": this.getAdminWatchList[this.activeTab]?.mwId,
          "mwName": this.getAdminWatchList[this.activeTab]?.mwName,
          "scripsIds": scripsIds
        }
        this.$store.dispatch('adminMw/deletemwScips', json)
        this.confirmDialog = false
        this.resetCheckbox()
      },
      async onOrOffSmartWatch(data) {
        let json = {
          "mwId": data?.mwId,
          "mwName": data?.mwName,
          "isEnable": String(this.adminSwitch)
        }
        await this.$store.dispatch('adminMw/onOrOffSmartWatch', json)
        this.confirmDialog = false
      },
      async sortMwlist(mw, array) {
        var sortArray = [];
        for (let i of array) {
            let temp = {
                id: i.id,
                sortOrder: array.indexOf(i)
            }
            sortArray.push(temp);
        }
        var json = {
            mwId: mw.mwId,
            mwName: mw?.mwName,
            scrips: sortArray
        };
        await this.$store.dispatch('adminMw/sortPredefMw', json)
      },
      async multiDelete() {
        this.isMultiDelete = true
        this.confirmDialog = true
      },
      async resetCheckbox() {
        this.selectAll = false
        this.selectedCheckbox = []
      }
    },
}
</script>